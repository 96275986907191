import React from "react";
import Container from "../../container";
import Box from "../../box";
import AvatarList from "../list";

const AvatarListSection = ({ people, openPerson, playAnimations }) => {

    const [loaded, setLoaded] = React.useState(false)

    return (
        <Container
            appear={"fromRight"}
            onAppear={() => setLoaded(true)}
        >
            <Box className={"avatar-container"}>
                <Box
                    className={"avatar-wrapper"}
                    background={"gray-light"}
                    padding={"vertical"}
                >
                    <h2 className={"margin-left-1"}>Mehr über...</h2>
                    <AvatarList
                        people={people}
                        onAvatarClick={openPerson}
                        playAnimations={loaded && playAnimations}
                    />
                </Box>
            </Box>
        </Container>
    )
}

export default AvatarListSection;