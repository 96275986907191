import React from "react"

import clsx from "clsx"
import PropTypes from "prop-types"

import Icon from "../index"
const IconButton = (props) => {

  const {disabled, onClick, children, ...iconProps} = props

  return (
    <div
      role={"button"}
      tabIndex={0}
      className={clsx("icon-button",
        disabled && "disabled",
      )}
      onClick={onClick}
      onKeyPress={event => event.key === "Enter" && onClick()}
    >
      { children ? children : <Icon { ...iconProps } /> }
    </div>
  )
}

IconButton.propTypes = {
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  ...Icon.propTypes,
}

export default IconButton
