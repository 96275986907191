import React, { Component } from "react"

import clsx from "clsx"
import PropTypes from "prop-types"

import Responsive from "../../utils/responsive"

class Modal extends Component {

  render() {

    if(typeof window !== 'undefined' && typeof document !== 'undefined') {

      if(this.props.open) {
        document.body.setAttribute("style",
          `top: -${window.scrollY}px; position: fixed;`
        )
      }
      else {
        const scrollY = document.body.style.top

        document.body.setAttribute("style", "")
        window.scrollTo(0, parseInt(scrollY || '0') * -1)
      }
    }

    return (
      <div className={clsx("modal", this.props.className,
        this.props.open && "open",
        this.props.background && `background-${this.props.background}`,
        this.props.visibleTo && `visible-to-${this.props.visibleTo}`,
      )}>
        <div
          role="button"
          aria-label={"close"}
          tabIndex={0}
          className="background"
          onClick={this.props.onClick}
          onKeyPress={event => event.key === "Enter" && this.props.onClick}
        />
        <div className="content">
          { this.props.children }
        </div>
      </div>
    )
  }
}

Modal.propTypes = {
  className: (
    PropTypes.string
  ),
  open: (
    PropTypes.bool
  ),
  onClick: (
    PropTypes.func
  ),
  background: PropTypes.oneOf([
    "transparent", "darken",
  ]),
  visibleTo: PropTypes.oneOf(
    Object.keys(Responsive.breakpoints)
  ),
}

Modal.defaultProps = {
  open: false,
  background: "transparent",
  visibleTo: "xl",
}

export default Modal