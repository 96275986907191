import React from "react"

import clsx from "clsx"
import PropTypes from "prop-types"

import Responsive from "../../utils/responsive"

const Popup = (props) => (
  <div className={clsx("popup", props.className,
    props.open && "open",
    props.visibleTo && `visible-to-${props.visibleTo}`,
  )}>
    { props.clickOnClose && <div
      role="button"
      aria-label={"close"}
      tabIndex={0}
      className="close"
      onClick={props.clickOnClose}
      onKeyPress={event => event.key === "Enter" && props.clickOnClose()}
    /> }
    <div className="content">
      { props.children }
    </div>
  </div>
)

Popup.propTypes = {
  className: PropTypes.string,
  open: PropTypes.bool,
  clickOnClose: PropTypes.func,
  visibleTo: PropTypes.oneOf(
    Object.keys(Responsive.breakpoints)
  ),
}

Popup.defaultProps = {
  className: "",
  open: false,
  clickOnClose: undefined,
  visibleTo: "xl",
}

export default Popup