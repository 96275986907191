import React from "react"

import clsx from "clsx"
import PropTypes from "prop-types"

import Animation from "../../animation"
import Image from "../../image"

const AnimatedAvatar = (props) => {

  const name = props.person.frontmatter.firstName
      + " " + props.person.frontmatter.lastName
  const shortName = props.person.frontmatter.firstName
  const avatar = props.person.frontmatter.avatar
  const animation = props.person.frontmatter.animation

  const [hover, setHover] = React.useState(false)

  const playAnimation = () => {
    setHover(true)
    props.onEnter()
  }
  const pauseAnimation = () => {
    setHover(false)
    props.onLeave()
  }

  const handleClick = () => {
    if(props.onClick) {
      pauseAnimation()
      props.onClick(props.person)
    }
  }
  const handleKeyPress = event => {
    event.key === "Enter" && handleClick()
  }

  return (
      <div
          role={"button"}
          tabIndex={0}
          className={clsx("avatar",
              props.onClick && "clickable"
          )}
          title={name}
          onMouseEnter={playAnimation}
          onFocus={playAnimation}
          onMouseLeave={pauseAnimation}
          onBlur={pauseAnimation}
          onClick={handleClick}
          onKeyPress={handleKeyPress}
      >
        { animation && <Animation
            className={"animation"}
            src={animation}
            autoplay={props.autoplay}
            paused={props.paused && !(hover)}
            loop={props.loop || hover}
            onComplete={props.onComplete}
            tabIndex={-1}
        /> }
        { !(animation) && <Image src={avatar} tabIndex={-1} /> }
        <span>{ shortName }</span>
      </div>
  )
}

AnimatedAvatar.propTypes = {
  className: (
      PropTypes.string
  ),
  person: (
      PropTypes.object
  ),
  autoplay: (
      PropTypes.bool
  ),
  loop: (
      PropTypes.bool
  ),
  paused: (
      PropTypes.bool
  ),
  onEnter: (
      PropTypes.func
  ),
  onLeave: (
      PropTypes.func
  ),
  onClick: (
      PropTypes.func
  ),
  onComplete: (
      PropTypes.func
  ),
}

AnimatedAvatar.defaultProps = {
  loop: false,
  paused: true,
}

export default AnimatedAvatar
