import React from "react"
import {graphql} from "gatsby"
import {MDXRenderer} from "gatsby-plugin-mdx"

import Layout from "../../components/layout"
import SEO from "../../components/layout/seo"

import Timeline from "../../components/timeline"
import Carousel from "../../components/carousel"

import HeaderSection from "../../components/header"
import Section from "../../components/section"
import Container from "../../components/container"
import Box from "../../components/box"

import Balloon from "../../components/balloon";
import ImageList from "../../components/image/list"
import AvatarList from "../../components/avatar/list"
import MediaQuery from "react-responsive/src/Component";
import Image from "../../components/image";
import ButtonLink from "../../components/button/link";
import Video from "../../components/video";
import useTimer from "../../utils/useTimer";
import Resp from "../../utils/responsive";
import AvatarListSection from "../../components/avatar/section/list";
import AvatarModalSection from "../../components/avatar/section/modal";

const AboutUsPage = ({_path, data}) => {

    const episodesAllMdxEdges = data.episodesAllMdx.edges.map((edge) => ({
        node: {
            ...edge.node,
            frontmatter: {
                ...edge.node.frontmatter,
                oakies: (
                    edge.node.frontmatter.oakies
                        ? edge.node.frontmatter.oakies.map((oakie) => ({
                            node: oakie.src.childMdx,
                        }))
                        : undefined
                ),
            },
        },
    }))

    return (
        <Layout path={"/ueber-uns"}>
            <SEO title="Über uns"/>
            <HeaderSection
                title={"Über uns"}
                image={data.headerImage.childImageSharp}
                type={"image-fullscreen"}
            />
            <GoalSection/>
            <ImageVideoSection/>
            <BalloonSection person={data.balloonPerson}/>
            <TimelineSection
                episodes={episodesAllMdxEdges}
            />
            <BraunschweigSection
                images={data.braunschweigImages.edges}
            />
            <OldenburgSection
                images={data.oldenburgImages.edges}
            />
        </Layout>
    )
}

const GoalSection = () => (
    <Section id={"GoalSection"}>
        <Container justify={"center"} appear={"fromBottom"}>
            <Box alignText={"center"} padding={"horizontal"}>
                <p className={"text-size-bigger"}>
                    <span>Unser Ziel</span>
                    <span className={"text-weight-bold"}>
            &ensp;&ndash;&ensp;
          </span>
                    <span>Ein Unternehmen,</span>
                </p>
                <p className={"text-size-bigger"}>
                    <span>in dem </span>
                    <span className={"text-color-red text-weight-bold"}>
            großartige Menschen
          </span>
                    <span> gerne arbeiten.</span>
                </p>
                <p className={"text-size-bigger text-color-red text-weight-bold"}>
                    &ndash;
                </p>
                <p className={"text-size-big"}>
                    Wir haben die Vision, unsere eigene Form von Unternehmen und Arbeit zu etablieren. Dabei lösen wir
                    uns von
                    abhaltenden Hierarchien und Strukturen, sodass neue, gute Ideen ihren Platz finden.
                </p>
            </Box>
        </Container>
    </Section>
)

const ImageVideoSection = () => (
    <Section id={"ImageVideoSection"}>
        <Container appear={"fromBottom"}>
            <Box>
                <Video dir={"image"} source={"https://red-oak-consulting.com/static/large/imagefilm.mp4"}/>
            </Box>
        </Container>
    </Section>
)

const BalloonSection = ({person}) => (
    <Section id={"BalloonSection"}>
        <Container justify={"right"} appear={"fromLeft"}>
            <Balloon justify={"right"} author={person}>
                <p>
                    Wir hinterfragen stetig die Konsequenzen und den Sinn unserer Tätigkeit.
                    Bei Red Oak wissen wir, was wir mit unserer Arbeit erreichen können und
                    arbeiten Aufgaben nicht einfach stumpf ab.
                </p>
            </Balloon>
        </Container>
    </Section>
)

const TimelineSection = ({episodes}) => {

    const [episodeIndex, setEpisodeIndex] = React.useState(0)
    const [popupIndex, setPopupIndex] = React.useState(null)

    const [autoplay, setAutoplay] = React.useState(false)
    const mediaGreaterEqualMedium = Resp.getCurrentBreakpoint() >= Resp.breakpoints.md
    useTimer(10000, () => setEpisodeIndex(
        (episodeIndex + 1) % 6 // episode count: 6
    ), mediaGreaterEqualMedium && autoplay)

    const openCarousel = (index) => {

        if (window.timelineCarousel) {
            window.timelineCarousel.slickGoTo(index, true)
        }
        setPopupIndex(index)
    }
    const closeCarousel = () => setPopupIndex(null)

    return (
        <Section id={"TimelineSection"}>
            <Container onAppear={() => setAutoplay(false)}>
                <Box padding={"horizontal"}>
                    <h2>Was bisher geschah</h2>
                    <Timeline
                        episodeIndex={episodeIndex}
                        popupIndex={popupIndex}
                        clickOnEvent={(idx) => {
                            setEpisodeIndex(idx)
                            openCarousel(idx)
                        }}
                        clickOnClose={() => closeCarousel()}
                        carouselReference={ref => window.timelineCarousel = ref}
                    >
                        {episodes.map(episode => (
                            <Box>
                                <MDXRenderer>{episode.node.body}</MDXRenderer>
                                {episode.node.frontmatter.oakies && (<>
                                    <h2>Neue Oakies</h2>
                                    <AvatarList
                                        people={episode.node.frontmatter.oakies}
                                        itemsPerRow={0}
                                        expandable={false}
                                        animated={false}
                                    />
                                </>)}
                                {!(episode.node.frontmatter.oakies) && (
                                    <ButtonLink to={"/karriere"}>freie Stellen</ButtonLink>
                                )}
                            </Box>
                        ))}
                    </Timeline>
                </Box>
            </Container>
        </Section>
    )
}


const BraunschweigSection = ({images}) => {

    const [autoSlide, setAutoSlide] = React.useState(false)

    return (
        <Section id={"BraunschweigSection"}>
            <Container
                alignItems={"end"}
                appear={"fromRight"}
                onAppear={() => setAutoSlide(true)}
            >
                <MediaQuery minWidth={768}>
                    <Box padding={"horizontal"}>
                        <ImageList
                            title={"Braunschweig"}
                            images={images}
                            altPrefix={"Braunschweig"}
                            background={"right"}
                        />
                    </Box>
                </MediaQuery>
                <MediaQuery maxWidth={767.999}>
                    <Box size={"2-to-3"} padding={"horizontal"}>
                        <h2>Braunschweig</h2>
                        <Box>
                            <Carousel autoplay={autoSlide} dots={true}>
                                {images.map((edge, _index) => (
                                    <Box key={edge.node.id}>
                                        <Image fluid={edge.node.childImageSharp.fluid}/>
                                    </Box>
                                ))}
                            </Carousel>
                        </Box>
                    </Box>
                </MediaQuery>
            </Container>
        </Section>
    )
}

const OldenburgSection = ({images}) => {

    const [autoSlide, setAutoSlide] = React.useState(false)

    return (
        <Section id={"OldenburgSection"}>
            <Container
                alignItems={"end"}
                appear={"fromRight"}
                onAppear={() => setAutoSlide(true)}
            >
                <MediaQuery minWidth={768}>
                    <Box padding={"horizontal"}>
                        <ImageList
                            title={"Oldenburg"}
                            images={images}
                            altPrefix={"Oldenburg"}
                            background={"left"}
                        />
                    </Box>
                </MediaQuery>
                <MediaQuery maxWidth={767.999}>
                    <Box size={"2-to-3"} padding={"horizontal"}>
                        <h2>Oldenburg</h2>
                        <Box>
                            <Carousel autoplay={autoSlide} dots={true}>
                                {images.map((edge, _index) => (
                                    <Box key={edge.node.id}>
                                        <Image fluid={edge.node.childImageSharp.fluid}/>
                                    </Box>
                                ))}
                            </Carousel>
                        </Box>
                    </Box>
                </MediaQuery>
            </Container>
        </Section>
    )
}

const AvatarSection = ({people}) => {

    const [personIndex, setPersonIndex] = React.useState(null);

    const peopleWithPopup = people
        .filter(person => person.node.frontmatter.popup)
        .map(person => person.node.frontmatter.position);

    const openPerson = (positionOfPerson) => {

        const indexOfPerson = peopleWithPopup.indexOf(positionOfPerson);

        if (window.personCarousel && indexOfPerson !== -1) {
            window.personCarousel.slickGoTo(indexOfPerson, true)
        }
        setPersonIndex(positionOfPerson)
    }
    const closePerson = () => setPersonIndex(null)

    return (
        <Section>
            <AvatarListSection
                people={people}
                openPerson={openPerson}
                playAnimations={personIndex === null}
            />
            <AvatarModalSection
                people={people}
                closePerson={closePerson}
                personIndex={personIndex}/>
        </Section>
    )
}

export default AboutUsPage

export const query = graphql`
    query AboutUsQuery {
        site {
            siteMetadata {
                title
            }
        }
        headerImage: file(relativePath: {eq: "about/header.jpg"}) {
            childImageSharp {
                fluid(maxWidth: 1200) {
                    ...GatsbyImageSharpFluid
                }
            }
        }
        balloonPerson: mdx(fileAbsolutePath: {regex: "/people/dean-eckert/"}) {
          frontmatter {
            avatar
            firstName
            lastName
          }
        }
        episodesAllMdx: allMdx(
            filter: { fileAbsolutePath: { regex: "/episodes/" } }
            sort: { order: ASC, fields: [frontmatter___position] }
        ) {
            edges {
                node {
                    id
                    body
                    frontmatter {
                      oakies {
                        src {
                          childMdx {
                            id
                            body
                            frontmatter {
                              firstName
                              lastName
                              jobTitle
                              avatar
                              animation                             
                            }
                          }
                        }
                      }
                    }                    
                }
            }
        }
        valuesAllMdx: allMdx(
            filter: { fileAbsolutePath: { regex: "/values/" } }
            sort: { order: ASC, fields: [frontmatter___position] }
        ) {
            edges {
                node {
                    id
                    body
                }
            }
        }
        braunschweigImages: allFile(
            filter: {relativePath: {regex: "/about/braunschweig/bs-[1-3]/"}},
            sort: {fields: name}
        ) {
            edges {
                node {
                    id
                    childImageSharp {
                        fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
        oldenburgImages: allFile(
            filter: {relativePath: {regex: "/about/oldenburg/ol-[1-3]/"}},
            sort: {fields: name}
        ) {
            edges {
                node {
                    id
                    childImageSharp {
                        fluid(maxWidth: 800) {
                            ...GatsbyImageSharpFluid
                        }
                    }
                }
            }
        }
        peopleAllMdx: allMdx(
            filter: { fileAbsolutePath: { regex: "/people/" } }
            sort: { order: ASC, fields: [frontmatter___position] }
        ) {
            edges {
                node {
                    id
                    body
                    frontmatter {
                        position
                        popup
                        firstName
                        lastName
                        jobTitle
                        avatar
                        animation
                        portrait {
                            childImageSharp {
                                fluid(maxWidth: 800) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                        landscape {
                            childImageSharp {
                                fluid(maxWidth: 800) {
                                    ...GatsbyImageSharpFluid
                                }
                            }
                        }
                    }
                }
            }
        }
    }
`
