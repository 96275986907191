import React from "react"

import clsx from "clsx"
import PropTypes from "prop-types"
import { MDXRenderer } from "gatsby-plugin-mdx"

import Image from "../image"

const Person = (props) => {

  const frontmatter = props.person.frontmatter
  const portrait = frontmatter.portrait && frontmatter.portrait.childImageSharp
    ? frontmatter.portrait.childImageSharp
    : undefined
  const landscape = frontmatter.landscape && frontmatter.landscape.childImageSharp
    ? frontmatter.landscape.childImageSharp
    : undefined

  const name = `${frontmatter.firstName} ${frontmatter.lastName}`
  const cssClass = `${frontmatter.firstName.toLowerCase()}`
    + `-${frontmatter.lastName.toLowerCase()}`

  return (
    <div className={"person"}>
      { (portrait || landscape) && (
        <div className={clsx("photo",
          landscape === undefined && "portrait-as-landscape"
        )}>
          { portrait && <Image className={"portrait"} fluid={portrait.fluid} /> }
          { landscape && <Image className={"landscape"} fluid={landscape.fluid} /> }
          <div className={"overlay"} />
        </div>
      ) }
      <div className={"description"}>
        <h2 className={"title"}>{ name }</h2>
        <div className={clsx("text", cssClass)}>
          <MDXRenderer>{ props.person.body }</MDXRenderer>
        </div>
      </div>
    </div>
  )
}

Person.propTypes = {
  className: (
    PropTypes.string
  ),
  person: (
    PropTypes.object
  ),
}

export default Person