import React, {Component} from "react"

import clsx from "clsx"
import PropTypes from "prop-types"

import IconButton from "../icon/button"
import Modal from "../modal"
import Popup from "../popup"
import MediaQuery from "react-responsive"
import Carousel from "../carousel"

class Timeline extends Component {

  render() {

    return (
      <div className={clsx("timeline", this.props.className)}
           onMouseEnter={this.props.onMouseEnter}
           onMouseLeave={this.props.onMouseLeave}
      >
        {React.Children.map(this.props.children, (
          child, idx
        ) => (
          <div className={"episode"}>
            <div className={clsx("event",
              idx === this.props.episodeIndex && "active",
            )}>
              <IconButton
                type={`number-${idx + 1}`}
                onClick={() => this.props.clickOnEvent(idx)}
              />
            </div>
            <div className={"line"}>
              <div className={"event-border"}/>
            </div>
          </div>
        ))}
        <div className={"description-wrapper"}>
          {React.Children.map(this.props.children, (
            child, idx
          ) => (
            <div className={clsx("description",
              idx === this.props.episodeIndex && "open")}
            >
              {child}
            </div>
          ))}
        </div>
        <MediaQuery maxWidth={767.999}>
          <Modal
            className={"description-wrapper"}
            background={"darken"}
            open={this.props.popupIndex !== null}
            onClick={this.props.clickOnClose}
          >
            <Popup
              open={true}
              clickOnClose={this.props.clickOnClose}
            >
              <Carousel
                autoplay={false}
                dots={false}
                carouselReference={
                  ref => this.props.carouselReference && this.props.carouselReference(ref)
                }
              >
                {this.props.children}
              </Carousel>
            </Popup>
          </Modal>
        </MediaQuery>
      </div>
    )
  }
}

Timeline.propTypes = {
  className: (
    PropTypes.string
  ),
  popupIndex: (
    PropTypes.number
  ),
  clickOnEvent: (
    PropTypes.func
  ),
  clickOnClose: (
    PropTypes.func
  ),
  carouselReference: (
    PropTypes.func
  )
}

Timeline.defaultProps = {
  popupIndex: null,
}

export default Timeline