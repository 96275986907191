import { useCallback, useEffect } from "react"

const useTimer = (time, callback, active) => {

  const timeoutCallback = useCallback(() => {

    let interval = null

    if(active) {
      interval = setInterval(() => { callback() }, time)
    }
    else if(interval !== null ) {
      clearInterval(interval)
    }

    return () => clearInterval(interval)

  }, [callback, time, active])

  useEffect(timeoutCallback, [timeoutCallback])

}

export default useTimer