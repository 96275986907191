import React from "react"
import clsx from "clsx"
import PropTypes from "prop-types"
import styles from "./index.module.scss"

import Image from "../index"
import Carousel from "../../carousel";
import {cls} from "../../../utils/styles";

const ImageList = (props) => {

  const Wrapper = props.internalWrapper
    ? props.internalWrapper
    : (props) => <div {...props}/>

  return (
    <div className={clsx("image-list2", styles.imageList,
      cls("background", props.background, styles)
    )}>
      { props.title && <h2 className={styles.title}>{ props.title }</h2> }
      <Wrapper className={styles.images} {...props} >
        {props.images.map((edge, idx) => (
          <Image
            className={styles.image}
            key={`${props.altPrefix} - Bild ${idx + 1}`}
            alt={`${props.altPrefix} - Bild ${idx + 1}`}
            fluid={edge.node.childImageSharp.fluid}
          />
        ))}
      </Wrapper>
    </div>
  )
}

ImageList.propTypes = {
  images: (
    PropTypes.array.isRequired
  ),
  altPrefix: (
    PropTypes.string.isRequired
  ),
  background: PropTypes.oneOf([
    "left", "right",
  ]),
  title: (
    PropTypes.string
  ),
}

export const ImageListCarousel = (props) => {
  const InternalCarousel = (props) => (
    <Carousel
      autoplay={true}
      autoplaySpeed={4000}
      slidesToShow={3}
      dots={false}
      {...props}
    />
  )
  return <ImageList internalWrapper={InternalCarousel} {...props}/>
}

export default ImageList