import Modal from "../../modal";
import Popup from "../../popup";
import Carousel from "../../carousel";
import Person from "../../person";
import React from "react";

const AvatarModalSection = ({ personIndex, closePerson, people }) => (
    <Modal
        className={"people-modal"}
        background={"darken"}
        open={personIndex !== null}
        onClick={closePerson}
    >
        <Popup
            open={personIndex !== null}
            clickOnClose={closePerson}
        >
            <Carousel
                autoplay={false}
                dots={false}
                carouselReference={ref => window.personCarousel = ref}
            >
                { people.map( (item) => (
                    item.node.frontmatter.popup && <Person person={ item.node ? item.node : item.src.childMdx } />
                )) }
            </Carousel>
        </Popup>
    </Modal>
)

export default AvatarModalSection;