import React from "react"

import clsx from "clsx"
import PropTypes from "prop-types"

const Video = (props) => {

  const { source, dir, src, title, className, tabIndex } = props
  const dirPath = dir ? `/assets/videos/${dir}` : undefined

  return (
      <div className={clsx("video", className,
          dir === undefined && src !== undefined && "youtube"
      )} tabIndex={tabIndex}>
        { dirPath !== undefined
            ? <video controls controlsList={"nodownload"}
                poster={`${dirPath}/placeholder.jpg`}
                placeholder={`${dirPath}/placeholder.jpg`}
                onEnded={ e => e?.target?.load && e.target.load() }
              >
                <source type={"video/mp4"} src={source || `${dirPath}/video.mp4`} />
                <track default kind={"captions"}
                   srclang={"German/Deutsch"}
                   src={`${dirPath}/captions.de.vtt`}
                />
                Entschuldigung, aber Ihr Browser unterstützt keine eingebetteten Videos.
              </video>
            : src !== undefined
                ? <iframe src={src} title={title} allowFullScreen />
                : "Keine Quelle."
        }
      </div>
  )
}

Video.propTypes = {
  src: (
      PropTypes.string
  ),
  title: (
      PropTypes.string
  ),
  source: (
    PropTypes.string
  ),
  dir: (
      PropTypes.string
  ),
  className: (
      PropTypes.string
  ),
  tabIndex: (
      PropTypes.number
  ),
}

export default Video
