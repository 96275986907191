import React from "react"

import PropTypes from "prop-types"

import AnimatedAvatar from "../animated"
import IconButton from "../../icon/button"

import clsx from "clsx"
import Responsive from "../../../utils/responsive"
import Avatar from "../index";

/*itemsPerRow ändert nicht Anzahl Avatare. Stattdessen zu index.scss und $breakpoint_counts ändern*/
const AvatarList = (props) => {
  const expandable = Responsive.getCurrentBreakpoint() <= Responsive.breakpoints.sm

  const [state, setState] = React.useState({
    breakpoint: Responsive.getCurrentBreakpoint(),
    expanded: !(expandable) || props.expanded,
    playedIndices: [],
    playIndex: getPlayIndex(props.people, [],
      !(expandable) || props.expanded, Responsive.getCurrentBreakpoint()),
    hover: false,
  })

  const playNext = () => {

    const indices = [...state.playedIndices, state.playIndex]
    const index = getPlayIndex(
      props.people, indices, state.expanded, state.breakpoint
    )
    setState({
      ...state,
      playedIndices: index !== undefined ? indices : [],
      playIndex: index !== undefined ? index : getPlayIndex(
        props.people, [],
        state.expanded, state.breakpoint
      ),
    })
  }

  return (
    <div className={clsx("avatar-list",
      props.itemsPerRow && `items-per-row-${props.itemsPerRow}`,
      state.expanded && "expanded",
    )}>
      {props.people.map((item, index) => {

        const person = item.node ? item.node : item.src.childMdx
        const paused = !(props.playAnimations
          && !(state.hover) && index === state.playIndex)

        return props.animated
          ? <AnimatedAvatar
            key={`avatar-list-entry-${person.id}`
            + `-${person.frontmatter.lastName}`}
            person={person}
            loop={false}
            paused={paused}
            onEnter={() => setState({ ...state, hover: true })}
            onLeave={() => setState({ ...state, hover: false })}
            onClick={person.frontmatter.popup && props.onAvatarClick
              ? () => props.onAvatarClick(person.frontmatter.position)
              : undefined
            }
            onComplete={paused ? undefined : playNext}
          />
          : <Avatar
            key={`avatar-list-entry-${person.id}`}
            person={person}
            onClick={person.frontmatter.popup && props.onAvatarClick
              ? () => props.onAvatarClick(person.frontmatter.position)
              : undefined
            }
          />
      })}
      {expandable && <IconButton
        onClick={() => setState({...state, expanded: !(state.expanded)})}
        type={"arrow"}
        rotate={state.expanded ? 90 : 270}
      />}
    </div>
  )
}

const getPlayIndex = (people, playedIndices, expanded, breakpoint) => {
  const maxIndex = getMaxIndex(breakpoint, people, expanded)
  const indices = getValidIndices(people, maxIndex, playedIndices)
  const index = Math.floor(Math.random() * indices.length)

  return indices[index]
}

const getMaxIndex = (breakpoint, people, expanded) => !(expanded)
  ? breakpoint < Responsive.breakpoints.sm
    ? 6 : breakpoint < Responsive.breakpoints.lg
      ? 8 : 12
  : people.length

const getValidIndices = (people, maxIndex, playedIndices) => people.map(
  (item, index) => ({...item, index: index})
).filter(
  (item, index) => index < maxIndex
    && item.node.frontmatter.animation !== undefined
    && item.node.frontmatter.animation !== null
    && playedIndices.indexOf(index) === -1
).map(
  (item) => item.index
)

AvatarList.propTypes = {
  people: PropTypes.array,
  onAvatarClick: PropTypes.func,
  itemsPerRow: PropTypes.oneOf([
    0, 8,
  ]),
  //expandable: PropTypes.bool,
  expanded: PropTypes.bool,
  playAnimations: PropTypes.bool,
}

AvatarList.defaultProps = {
  people: undefined,
  onAvatarClick: undefined,
  itemsPerRow: 5,
  //expandable: false,
  expanded: false,
  animated: true,
  playAnimations: false,
}

export default AvatarList